"use client";
import React from "react";
import { Next13ProgressBar } from "next13-progressbar";
import { NextUIProvider } from "@nextui-org/react";

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {/* <NextUIProvider locale="es-AR"> */}
      {children}
      <Next13ProgressBar
        height="4px"
        color="#0A2FFF"
        options={{ showSpinner: true }}
        showOnShallow
      />
      {/* </NextUIProvider> */}
    </>
  );
};

export default Providers;
