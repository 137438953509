"use client";
import "react-toastify/dist/ReactToastify.css";
import FixedToastContainer from "@/components/FixedToastContainer";
import "./global.css";
import { Nunito } from "next/font/google";
import Script from "next/script";
import dayjs from "dayjs";
import Providers from "./providers";
import { usePathname, useRouter } from "next/navigation";
import { Button } from "@nextui-org/react";
import { FiArrowLeft } from "react-icons/fi";
import { Head } from "next/document";
import Link from "next/link";
require("dayjs/locale/es-us");
dayjs.locale("es-us");

const nunito = Nunito({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-nunito",
});

const IndexLayout = ({ children }: any) => {
  const pathname = usePathname();
  const router = useRouter();
  return (
    <html
      lang="es"
      className={`${nunito.variable} font-nunito h-full bg-default bg-cover`}
    >
      <body
        className={` ${
          pathname === "/informationPanel"
            ? "h-screen mx-auto bg-default bg-repeat-y bg-cover bg-center"
            : "h-full sm:py-8 sm:max-w-lg mx-auto bg-default bg-cover bg-center bg-no-repeat"
        }
	`}
      >
        <div
          className={`
        ${
          pathname === "/informationPanel"
            ? "h-auto w-screen overflow-x-hidden overflow-y-auto"
            : "bg-white  h-full w-full relative sm:rounded-xl shadow-md  overflow-x-hidden overflow-y-auto"
        }
        `}
        >
          <Providers>{children}</Providers>
        </div>
        <FixedToastContainer position="top-right" />
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-KQRDZZJP6V"
        />
        <Script async src="/scripts/googleAnalyticsScript.js" />
      </body>
    </html>
  );
};

export default IndexLayout;
